import { template as template_9437117a3f2344499c9088d6162fb2a0 } from "@ember/template-compiler";
const FKText = template_9437117a3f2344499c9088d6162fb2a0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
