import { template as template_a5b460ffb8624c2a82a4862cc0bc93c2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_a5b460ffb8624c2a82a4862cc0bc93c2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
