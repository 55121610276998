import { template as template_66e120d64f6f411a95b7537cede56e46 } from "@ember/template-compiler";
const FKLabel = template_66e120d64f6f411a95b7537cede56e46(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
