import { template as template_5e5b00021814413891774ae94cef3db3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5e5b00021814413891774ae94cef3db3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
